<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-divider></v-divider>

    <v-row justify="end" align="end">
      <v-col cols="12" md="6" align-self="end" class="text-right">
        <v-btn
          small
          dark
          outlined
          :color="$store.state.primaryColor"
          @click="demo"
        >
          <v-icon small left>gamepad</v-icon>
          <span class="caption text-capitalize">Demo</span>
        </v-btn>
        <v-btn
          class="mx-3"
          small
          dark
          :color="$store.state.secondaryColor"
          :loading="loading"
          @click="uploadData"
        >
          <v-icon small left>save</v-icon>
          <span class="caption text-capitalize">{{ $t("register") }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <small>*{{ $t("indicated required field") }}</small>

    <v-divider class="my-2"></v-divider>
    <v-layout row wrap class="caption mt-1">
      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("selected business") }} *</span
        >
        <v-text-field
          v-if="selectedBusiness"
          outlined
          dense
          v-model="selectedBusinessName"
          readonly
          :rules="rules.emptyField"
        ></v-text-field>

        <v-select
          v-else
          outlined
          dense
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="register.businessID"
          :rules="rules.emptyField"
        ></v-select>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("supplier name") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="register.name"
          :rules="rules.emptyField"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("email") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="register.email"
          :rules="rules.email"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("telephone") }}*</span
        >
        <vue-tel-input
          defaultCountry="Ke"
          mode="International"
          v-model="register.phone"
          :rules="rules.emptyField"
          style="height: 40px"
        ></vue-tel-input>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="caption mt-1">
      <v-flex xs12 sm4 md4 lg4 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("PO BOX") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="register.address.pobox"
          :rules="rules.emptyField"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm4 md4 lg4 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("country") }}*</span
        >
        <v-combobox
          v-model="register.address.country"
          :items="countries"
          @change="selectCountry"
          :rules="rules.emptyField"
          outlined
          dense
        ></v-combobox>
      </v-flex>

      <v-flex xs12 sm4 md4 lg4 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("county-province") }}*</span
        >

        <v-combobox
          outlined
          dense
          v-model="register.address.county"
          :items="provinces"
          @change="selectCounty"
          :rules="rules.emptyField"
        ></v-combobox>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="caption mt-1">
      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("city-town") }}*</span
        >
        <v-combobox
          outlined
          dense
          :items="towns"
          v-model="register.address.city"
        ></v-combobox>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("district-territory") }}*</span
        >
        <v-combobox
          outlined
          dense
          v-model="register.address.territory"
          :items="territories"
        ></v-combobox>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("street") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="register.address.street"
          :rules="rules.emptyField"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("estate") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="register.address.estate"
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="caption mt-1">
      <v-flex xs12 sm6 md6 lg6 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("building") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="register.address.building"
          :rules="rules.emptyField"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("house-suite-number") }}.</span
        >
        <v-text-field
          outlined
          dense
          v-model="register.address.no"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("supplier added successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not add a supplier. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";

import countryList from "@/data/countries";
export default {
  data: () => ({
    snackSuccess: false,
    snackError: false,
    loading: false,
    timeout: 2000,
    provinces: [],
    towns: [],
    territories: [],
    countryList,
    register: {
      ownerId: "", //link with businesses
      name: "",
      email: "",
      phone: "",
      address: {
        country: "",
        county: "",
        city: "",
        pobox: "",
        street: "",
        estate: "",
        building: "",
        no: "",
      },
    },
  }),

  computed: {
    businessOwner() {
      return this.$store.state.currentUser;
    },
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
    countries() {
      return this.countryList.map((item) => item.country);
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: "ViewSuppliers" });
    },

    uploadData() {
      this.loading = true;
      this.register.ownerId = this.businessOwner;
      db.collection("suppliers")
        .add(this.register)
        .then(() => {
          this.loading = false;
          this.snackSuccess = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch(() => {
          this.snackError = true;
        });
    },

    selectCountry() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates
    },

    selectCounty() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates

      let i = region.findIndex(
        (x) => x.province == this.register.address.county
      );
      this.towns = region[i].towns;
      this.territories = region[i].territories;
    },

    demo() {
      this.register.name = "ABC Wholesalers";
      this.register.email = "abc@email.com";
      this.register.phone = "+254789098675";
      this.register.address.pobox = "90876 - 00200, NAIROBI";
      this.register.address.country = "Kenya";
      this.register.address.county = "Nairobi";
      this.register.address.city = "Nairobi";
      this.register.address.street = "Viwandani Road";
      this.register.address.estate = "Industrial Area";
      this.register.address.building = "Kiwanda House";
      this.register.address.no = "2FL";
    },
  },
};
</script>

<style></style>
